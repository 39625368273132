export const pt_brand = {
    /******************/
    /** Page titles */

    page_home: "Pagina inicial | Orozino",
    page_games: "Jogos | Orozino",
    page_game: "Jogo | Orozino",
    page_login: "Entrar | Orozino",
    page_registration: "Registro | Orozino",
    page_cashier: "Caixa | Orozino",
    page_profile: "Perfil | Orozino",
    page_forgot_password: "Esqueceu sua senha | Orozino",
    page_reset_password: "Redefinir senha | Orozino",
    page_region_blocked: "Região bloqueada | Orozino",
    page_verify_email: "Verificação de e-mail | Orozino",

    /******************/
    /** Brand Pages */

    // Home
    last_played_10: "Volte a entrar",
    last_played_20: "Cápsula do tempo",

    /******************/
    /** Brand components */

    // CashbackRoll.js
    cashback_roll_title: "Escolha e Ganhe!",
    cashback_roll_title_deposit: "Recarregue e tente a sorte!",
    cashback_roll_subtitle: <span>Desbloqueie seu prêmio em dinheiro reivindicando seu primeiro bônus!</span>,
    cashback_roll_subtitle_first_deposit: "Escolha um cartão e ganhe dinheiro instantâneo!",

    cashback_roll_next_ticket: "Próxima escolha disponível em:",
    cashback_roll_deposit_title: "Deposite e ganhe prêmios em dinheiro imediatamente!",
    cashback_roll_btn_deposit: "Deposite agora",
    cashback_roll_tickets_left: `Ingressos:`,
    cashback_roll_you_won: `Você ganhou`,
    cashback_roll_notification: ({ sign, amount }) => <span><span style={{ fontWeight: "600" }}>{sign}{amount}</span> foram adicionados à sua conta</span>,

    // HomeBanner.js
    home_banner_log_out_title_1: 'Bônus de boas-vindas',
    home_banner_log_out_title_2: <><span className="title-lg home_baner-promo_font">100%</span> e <span className="title-lg home_baner-promo_font">500 giros grátis!</span></>,
    home_banner_log_out_title_3: <><span className="subtitle-lg home_baner-promo_font">+ 30 giros grátis sem depósito</span></>,
    home_banner_log_in_title: 'Bem-vindo ao Orozino!',
    home_banner_log_in_title_name: function (name) { return `'Bem-vindo, ${name}` },
    home_banner_log_in_text: "Que você encontre ouro hoje!",
    home_banner_log_in_deposit_text: "Que você encontre ouro hoje!",
    home_banner_log_in_deposit_cta: "Faça seu depósito agora",
    home_banner_log_in_vip_banner_surtitle: "Uma experiência de cassino de elite",
    home_banner_log_in_vip_banner_title: <>Deposite um total de <span className="primary"><b>$500</b></span> para obter <span className="primary"><b>benefícios VIP</b></span>.</>,
    home_banner_log_in_vip_banner_text: (
        <>
            <li>Payouts de alta prioridade</li>
            <li>Cashback mensal de 30% ou mais</li>
            <li>Bônus e presentes exclusivos</li>
            <li>Gerente pessoal</li>
        </>
    ),

    // ResponsibleGaming.js
    responsible_gaming_title: 'Jogo responsável',
    responsible_gaming_text: 'Acreditamos que é nossa responsabilidade com nossos jogadores e com a comunidade de jogos educar e fornecer suporte no momento certo.',
    responsible_gaming_cta: 'Saber mais',

    // FirstDepositPromo.js
    // first_deposit_promo_badge: "TEMPO LIMITADO DE OFERTA",
    // first_deposit_promo_title: <span>Reivindique qualquer bônus e tenha a chance de ganhar o&nbsp;<span className="fd_promo_highlight_registration">iPhone&nbsp;15!</span></span>,
    // first_deposit_promo_title_home: <span>Tenha a chance de ganhar <span className="fd_promo_highlight">iPhone&nbsp;15</span> reinvidicando seu primeiro bônus!</span>,
    // first_deposit_promo_title_registration: <span>Reivindique seu bônus e tenha a chance de ganhar&nbsp;<span className="fd_promo_highlight_registration">iPhone&nbsp;15!</span></span>,
    // first_deposit_promo_cta: 'Participe no sorteio',
    // first_deposit_promo_cta_home: 'Reivindique agora',
    registration_banner_title: <>Ganhe <span className="verify-phone-title-spins">30 giros grátis</span> sem necessidade de depósito!</>,
    registration_banner_subtitle: 'NÃO É NECESSÁRIO DEPÓSITO',
    fd_promo_deposit_title_interac: 'Seguro e Protegido com Interac!',
    fd_promo_deposit_subtitle_interac: '®Marca registrada da Interac Corp. Usado sob licença.',
    fd_promo_deposit_title_pix: 'Seguro e Protegido com PIX!',
    fd_promo_deposit_subtitle_pix: 'Usado sob licença. Parceiro de pagamento oficial.',
    deposit_banner_title_lottery: 'Complete seu depósito e ganhe até',
    deposit_banner_vip_title: <>Deposite um total de <span className="primary"><b>$500</b></span><br />para obter <span className="primary"><b>benefícios VIP</b></span>.</>,

    // GoogleButton.js
    google_button_text: 'Continuar com Google',

    // Footer.js
    footer_legal: "Orozino.com é operado pela Spinsoft Interactive N.V. (Reg no. 160756), Abraham de Veerstraat 9, Willemstad, P.O. Box 3421, Curaçao, que possui todos os direitos para operar o software de jogo e/ou oferecer jogos de azar sob a Licença Anjouan no. ALS l-192407033-Fl2. Para qualquer ajuda, entre em contato conosco em info@orozinosupport.com. Jogue com responsabilidade.",
    footer_legal_mock: <span>
        Esta declaração serve como uma declaração formal de que o conteúdo ou propriedade intelectual em questão está protegido pelas leis de direitos autorais. Significa que o criador ou detentor dos direitos reservou todos os privilégios previstos na lei de direitos autorais, como o direito de reproduzir, distribuir e adaptar a obra.
        <br /><br />
        O jogo responsável desempenha um papel vital na garantia de uma experiência positiva e agradável para os participantes, promovendo práticas seguras que ajudam os indivíduos a gerir as suas atividades dentro dos seus limites, protegendo-os assim contra os riscos de dependência e desafios relacionados, ao mesmo tempo que contribuem para o bem-estar geral. da comunidade, promovendo um ambiente de jogo seguro, inclusivo e responsável.
        <br /><br />
        Copyright © 2024 «Orozino»
        Todos os direitos reservados e protegidos por lei.
    </span>,
    footer_btn_terms: "Termos e Condições",
    footer_btn_privacy_policy: "Política de Privacidade",
    footer_btn_responsible_gaming: "Jogo responsável",
    footer_btn_dispute_resolution: "Resolução de disputas",
    footer_btn_self_exclusion: "Autoexclusão",
    footer_btn_terms_href: "https://cepgucehw.gleap.help/pt/articles/1-termos-e-condicoes",
    footer_btn_privacy_policy_href: "https://cepgucehw.gleap.help/pt/articles/4-politica-de-privacidade",
    footer_btn_responsible_gaming_href: "https://cepgucehw.gleap.help/pt/articles/3-jogo-responsavel",
    footer_btn_dispute_resolution_href: "https://cepgucehw.gleap.help/pt/articles/5-resolucao-de-disputas",
    footer_btn_self_exclusion_href: "https://cepgucehw.gleap.help/pt/articles/2-autoexclusao",

    question_mark_bonus: (
        <>
            <ul>
                <li>Aposta x30.</li>
                <li>Sem limite de saque.</li>
                <li>Bônus válido por 30 dias.</li>
            </ul>
            <p className="mb-0">Giros grátis disponíveis instantaneamente.</p>
        </>
    ),
    question_mark_pick_and_win: (
        <ul>
            <li>O jogo "Escolha & Ganhe" está disponível após cada depósito.</li>
            <li>Para receber um prêmio em dinheiro instantâneo, você precisa escolher uma das três cartas.</li>
            <li>Você terá 2 tentativas. Volte no dia seguinte e tente a sua sorte novamente.</li>
        </ul>
    ),
    question_mark_deposit_lottery: (
        <ul>
            <li>Você ganhará seu prêmio em dinheiro instantâneo jogando o jogo "Escolha & Ganhe" exclusivo da Orozino.</li>
            <li>Para receber seu prêmio em dinheiro você precisa escolher um dos três cartões disponíveis na página inicial.</li>
            <li>O jogo "Escolha & Ganhe" está disponível instantaneamente após o seu primeiro depósito.</li>
        </ul>
    ),
    question_mark_jackpot: (
        <ul>
            <li>Jackpots diários e horários são concedidos aleatoriamente aos jogadores após um giro (aposta).</li>
            <li>Cada giro (aposta) participa do sorteio dos jackpots.</li>
        </ul>
    ),
    question_mark_bonus_balance: (
        <ul>
            <li>O bônus precisa ser apostado x30 antes que os fundos estejam disponíveis para saque.</li>
            <li>Cada giro (aposta) elegível contribui com 100% para o cumprimento dos requisitos de apostas.</li>
            <li>Você pode retirar seus fundos antes de cumprir os requisitos de apostas do bônus, no entanto, todo o dinheiro do bônus será perdido.</li>
        </ul>
    ),

    // DepositMethod.js
    deposit_method_minimum_deposit_amount: 'O valor mínimo para pagamentos abaixo é de',

    //RetentionModals.js
    welcome_modal_cta: 'Reivindique agora',

    //HomeBannerTheme.js
    home_banner_log_out_surtile_theme: "OFERTA DE BEM-VINDO",
    home_banner_log_out_title_theme: "100% e 500 giros grátis!",
    home_banner_log_out_subtitle_theme: <><span className="primary">+ 30 giros grátis sem depósito!</span></>,
    home_banner_log_out_subtitle_theme_10: <><span className="primary">+ 10 giros grátis sem depósito!</span></>,
    home_banner_log_out_subtitle_theme_100: <><span className="primary">+ 100 giros grátis sem depósito!</span></>,
    home_banner_log_out_cta_theme: "Reivindique agora"
}