export const fr = {

    // ClaimWinningsAllowed.js
    claim_winnings_allowed_message: "Nous sommes en train d'examiner vos gains et nous vous contacterons dès que possible.",

    // WithdrawAllowed.js
    withdrawal_allowed_message: ({ sign, activeWithdrawal }) => <span>Vous avez actuellement un retrait en cours de {sign}{activeWithdrawal?.amount}. Veuillez patienter pendant que nous traitons votre retrait avant de faire une nouvelle demande.</span>,

    // BonusRowCategory.js
    bonus_row_title: "Choisissez votre premier bonus",
    bonus_row_title_2: "Choisissez votre prochain bonus",

    // BonusTile.js
    bonus_tile_cta: "Réclamez maintenant",

    // BonusTimer.js
    bonus_timer_expired: "Expire aujourd'hui !",

    // Deposit.js
    deposit_btn_back_to_amount: "Retour au montant du dépôt",
    deposit_btn_back_to_payment_options: "Retour aux options de paiement",

    // DepositAmountSection.js
    deposit_amount_title: "Montant",
    deposit_amount_cta: "Dépôt",
    deposit_amount_field: "Montant du dépôt",
    deposit_amount_field_label: (sign) => {
        return `Montant du dépôt (${sign})`
    },
    deposit_amount_limit: ({ sign, depositMinAmount, depositMaxAmount }) => {
        return `Le montant du dépôt doit être compris entre ${sign}${depositMinAmount} et ${sign}${depositMaxAmount}.`
    },
    deposit_amount_bonus_limit: ({ sign, depositMinAmount, depositMaxAmount }) => {
        return `Le montant du dépôt doit être compris entre ${sign}${depositMinAmount} et ${sign}${depositMaxAmount}.`
    },

    // DepositBonusCard.js
    deposit_bonus_card_min_deposit: "Dépôt min.:",

    // DepositBonusSection.js
    deposit_bonus_section_title: "Choisissez votre bonus",
    deposit_bonus_section_title_selected: "Bonus sélectionné :",

    // DepositPaymentMethod.js
    deposit_payment_method_title: "Choisissez votre méthode de paiement",
    deposit_payment_method_bonus: "Bonus supplémentaire de 15%",

    // DepositRedirect.js
    deposit_redirect_title: "Complétez votre paiement",
    deposit_redirect_cta: "Dépôt",
    deposit_redirect_cta_processing: "En cours de traitement",
    deposit_redirect_success_title: "Presque là!",
    deposit_redirect_success_text: "Cliquez ci-dessous pour recharger et commencer à jouer.",
    deposit_redirect_success_cta: "Complétez votre paiement",
    deposit_redirect_error: (error) => {
        return `${error ? error : 'Une erreur est survenue.'} Veuillez réessayer ou choisir un autre mode de paiement.`
    },
    deposit_redirect_field_first_name: "Prénom",
    deposit_redirect_field_last_name: "Nom de famille",
    deposit_redirect_field_email: 'Email',
    deposit_redirect_field_phone: "Téléphone",
    deposit_redirect_field_address: "Adresse",
    deposit_redirect_field_city: "Ville",
    deposit_redirect_field_state: "Province/État",
    deposit_redirect_field_zip: "Code Postal",

    // DepositSummary.js
    deposit_summary_amount: "Montant",
    deposit_summary_bonus: "Bonus",
    deposit_summary_no_bonus: "Aucun bonus sélectionné",

    // DepositToggle.js
    deposit_toggle_btn_deposit: "Dépôt",
    deposit_toggle_btn_withdraw: "Retrait",

    // FreespinsRow.js
    freespin_row_title: ({ number, game }) => <>Vous avez<span className="">{number} tours gratuits</span> dans<span className="">{game}</span>!</>,
    freespin_row_cta: "Jouez maintenant",

    // GameRowJackpotTypes.js
    jackpot_types_daily: "Jackpot quotidien",
    jackpot_types_hourly: "Jackpot horaire",
    jackpot_types_drops_in: "Tombes dans",

    // GameSearchField.js
    game_search_input_placeholder: "Entrez le jeu ou le fournisseur",
    game_search_input_hint: "Rechercher",

    // IdleTimer.js
    idle_timer_title: "La session a expiré",
    idle_timer_text: "Vous avez été inactif. Veuillez vous reconnecter.",
    idle_timer_cta: "Se connecter",

    // ModalClaimBonusWinnings.js
    claim_bonus_winnings_title: "Réclamez vos gains",
    claim_bonus_winnings_processing: "Traitement en cours...",
    claim_bonus_winnings_exceeded: "Votre demande a été envoyée pour approbation. Nous reviendrons vers vous dès que possible.",
    claim_bonus_winnings_error: "Une erreur s'est produite. Veuillez réessayer ou contacter le support.",

    // ModalCloseAccount.js
    close_account_title: "Fermer le compte",
    close_account_text: <span>Êtes-vous sûr de vouloir fermer votre compte?<br /><br /><i>In order to terminate your self-exclusion, log in with your email and password and follow further instructions.</i>, Afin de terminer votre auto-excmusion, connectez-vous avec votre email et votre mot de passe et suivez les instructions supplémentaires.</span>,
    close_account_cta: "Oui, fermez mon compte",

    // ModalOpenAccount.js
    open_account_title: "Compte fermé",
    open_account_text: "Votre compte est actuellement fermé. Souhaitez-vous rouvrir votre compte?",
    open_account_cta: "Oui, veuillez rouvrir mon compte",

    // Navigation.js
    navigation_btn_home: "Accueil",
    navigation_btn_games: "Jeux",
    navigation_btn_log_in: "Se connecter",
    navigation_btn_register: "S'inscrire",
    navigation_balance: "Solde",

    // PhoneVerification.js
    phone_verification_log_out_title: (number) => <>Obtenez<span className="verify-phone-title-spins">{number}</span> aucun dépôt&nbsp;requis!</>,
    phone_verification_log_in_title: (number) => <>Vérifiez votre numéro de téléphone et obtenez <span className="verify-phone-title-spins">{number}</span>!</>,
    phone_verification_field_code_title: "Entrez votre code de confirmation",
    phone_verification_field_phone_label: "Entrez votre numéro de téléphone mobile",
    phone_verification_field_code_label: "Code",
    phone_verification_btn_claim: "Réclamer maintenant",
    phone_verification_btn_verify_claim: "Vérifier et réclamer",
    phone_verification_btn_sms: "SMS",
    phone_verification_btn_phone_call: "Appel téléphonique",
    phone_verification_btn_confirm: "Confirmer",
    phone_verification_btn_cancel: "Annuler",
    phone_verification_btn_try_again: "Réessayer",
    phone_verification_confirmation_title: "Ce numéro est-il correct?",
    phone_verification_error_phone_already_verified: "Ce téléphone a déjà été vérifié.",
    phone_verification_error_wrong_phone: "Ce numéro de téléphone ne correspond pas à celui dans votre profil.",
    phone_verification_error_active_bonus: "Vous avez déjà un bonus. Veuillez actualiser la page.",
    phone_verification_error_general: "Une erreur s'est produite. Veuillez réessayer ou contacter notre équipe de support.",
    phone_verification_example: "Par exemple:",

    // ProfilePlayerInformation.js
    profile_information_title: "Informations de facturation",
    profile_information_btn_save: "Enregistrer",
    profile_information_field_first_name: "Prénom",
    profile_information_field_last_name: "Nom de famille",
    profile_information_field_email: "Email",
    profile_information_field_phone: "Téléphone",
    profile_information_field_address: "Adresse",
    profile_information_field_city: "Ville",
    profile_information_field_state: "Province/Région",
    profile_information_field_zip: "Code Postal",
    profile_information_field_dob: "Date de naissance",
    profile_information_notifications_title: 'Préférences de notification',
    profile_information_notifications_updates: "Recevoir des notifications concernant les mises à jour du compte, les dépôts, les retraits, les périodes d'indisponibilité et la maintenance planifiée.",
    profile_information_notifications_weekly: 'Recevoir des notifications sur les offres hebdomadaires gratuites, les mises à jour de bonus, les nouvelles promotions et les sorties de jeux.',

    // RegistrationForm.js
    registration_form_email_label: "Adresse email",
    registration_form_or: "OU",
    registration_form_email_placeholder: "nom@example.com",
    registration_form_password_label: "Mot de passe",
    registration_form_key_hint: "S'inscrire",
    registration_form_terms: <span>J'ai 18 ans et j'accepte <a href="https://cepgucehw.gleap.help/en/articles/4-privacy-policy" target="_blank">Confidentialité&nbsp;Politique</a> et <a href="https://cepgucehw.gleap.help/en/articles/1-terms-and-conditions" target="_blank">Conditions&nbsp;générales</a>.</span>,
    registration_form_btn_cta: "Créer un compte gratuit",
    registration_form_verify_email_title: "Veuillez vérifier votre email",
    registration_form_verify_email_subtitle: "Nous avons envoyé un email à l'adresse que vous avez fournie. Veuillez vérifier votre boîte de réception et suivre les instructions pour activer votre compte.",
    registration_form_error_email: "Veuillez fournir une adresse email valide.",
    registration_form_error_email_exists: <span>Cet email existe déjà.<br />Essayez peut-être de vous <a href="?login">connecter</a>?</span>,
    registration_form_error_password: "Le mot de passe doit comporter au moins 7 caractères",
    registration_form_error_terms: "Veuillez accepter nos Conditions Générales pour compléter l'inscription.",
    registration_form_error_general: "Un erreur est survenue. Veuillez réessayer.",
    registration_form_error_captcha: "Le défi CAPTCHA a échoué. Veuillez actualiser la page et réessayer",

    // WeeklyBonus.js
    weekly_bonus_cta: "Réclamez maintenant",

    // Withdraw.js
    withdraw_balance: "Solde disponible pour retrait",
    withdraw_bonus_balance: "Solde du bonus",
    withdraw_wager_progress: "Progression des mises",
    withdraw_choose_amount: "Saisir le montant à retirer",
    withdraw_choose_amount_label: (sign) => `Montant (${sign})`,
    withdraw_error_insufficient_funds: "Vous n'avez pas de fonds à retirer",
    withdraw_error_wrong_amount_limit_min: ({ sign, minAmount }) => `Fonds insuffisants. Le montant minimum de retrait est de ${sign}${minAmount}`,
    withdraw_error_wrong_amount_limit_min_max: ({ sign, minAmount, maxAmount }) => `Le retrait doit être compris entre ${sign}${minAmount} et ${sign}${maxAmount}`,
    withdraw_btn_back_to_payment_options: "Retour aux options de retrait",

    // WithdrawMethod.js
    withdraw_method_interac_legal: "®Marque déposée d'Interac Corp. Utilisée sous licence.",
    withdraw_method_choose_amount: "Veuillez entrer le montant pour procéder.",

    // WithdrawRedirect.js
    withdraw_redirect_title: "Complétez votre retrait.",
    withdraw_redirect_cta: "Retirer",
    withdraw_redirect_cta_processing: "En cours de traitement...",
    withdraw_redirect_success_title: "Succès!",
    withdraw_redirect_success_text_1: "Votre demande de retrait est en cours de traitement et devrait être terminée dans un délai de 1 à 5 jours ouvrables, en fonction de la méthode de retrait choisie.",
    withdraw_redirect_success_text_2: "Dès que votre retrait sera traité, le montant choisi sera déduit de votre solde de compte.",
    withdraw_redirect_error: (error) => {
        return `${error ? error : "Une erreur s'est produite."} Veuillez réessayer ou choisir une autre méthode de retrait.`;
    },
    withdraw_redirect_field_first_name: "Prénom",
    withdraw_redirect_field_last_name: "Nom de famille",
    withdraw_redirect_field_email: "Email",
    withdraw_redirect_field_phone: "Téléphone",
    withdraw_redirect_field_address: "Adresse",
    withdraw_redirect_field_city: "Ville",
    withdraw_redirect_field_state: "Province/Région",
    withdraw_redirect_field_zip: "Code Postal",
    withdraw_redirect_field_card_number: "Numéro de carte",
    withdraw_redirect_field_exp_month: "Mois d'expiration (MM)",
    withdraw_redirect_field_exp_year: "Année d'expiration (AA)",
    withdraw_redirect_wallet_id: "Identifiant du portefeuille",

    // WithdrawSummary.js
    withdrawal_summary_amount: "Montant",

    // ForgotPassword.js
    forgot_password_title: "Réinitialiser votre mot de passe",
    forgot_password_field_email: "Entrez votre e-mail d’inscription",
    forgot_password_text_1: "Vérifiez votre boîte de réception et le dossier spam.",
    forgot_password_text_2: "Si vous ne recevez pas d'e-mail dans les 30 minutes, veuillez essayer de soumettre à nouveau ce formulaire ou de contacter le support.",
    forgot_password_btn_close: "Fermer",
    forgot_password_btn_submit: "Soumettre",

    // Login.js
    login_forgot_password_cta: "Mot de passe oublié?",
    login_create_account: "Vous n'avez pas encore de compte?",
    login_create_account_cta: "Inscription",
    login_btn_close: "Fermer",
    login_cta: "Connexion",

    // Profile.js
    profile_title: "Profil client",
    profile_balance: "Solde Total",
    profile_balance_withdrawable: "Solde disponible",
    profile_balance_bonus: "Solde bonus",
    profile_wager_progress: "Progression du pari:",
    profile_bonus_promocode: "Code Promo",
    profile_bonus_expires: "Expire le",
    profile_btn_claim_winnings: "Réclamez vos gains",
    profile_btn_bonus_cancel: "Annuler le bonus",
    profile_btn_support: "Contacter le support",
    profile_close_account: "Attention ! Pour entamer la fermeture de votre compte, veuillez appuyer sur le bouton ci-dessous.",
    profile_btn_close_account: "Fermer le compte",
    profile_btn_log_out: "Se déconnecter",

    // RegionBlocked.js
    region_blocked_title: "Région bloquée.",

    // ResetPassword.js
    reset_password_title: "Réinitialisez votre mot de passe",
    reset_password_field_new_password: "Nouveau mot de passe",
    reset_password_field_new_password_error: "Veuillez entrer votre mot de passe",
    reset_password_field_confirm_password: "Confirmez le mot de passe",
    reset_password_field_confirm_password_error: "Veuillez ressaisir votre mot de passe",
    reset_password_error: "Une erreur s'est produite. Veuillez essayer de suivre à nouveau le lien de réinitialisation ou contacter le support.",
    reset_password_error_match: "Les mots de passe ne correspondent pas ! Veuillez ajuster et réessayer.",
    reset_password_cta: "Réinitialiser le mot de passe",
    reset_password_redirect_text: "Retour vers la page d’accueil...",

    // VerifyEmail.js
    verify_email_success_title: "Bravo! Votre email est vérifié",
    verify_email_success_subtitle: "Vous serez maintenant redirigé vers la page de connexion...",
    verify_email_error_title: "Votre compte est déjà vérifié",
    verify_email_error_subtitle: <span>Votre compte est déjà vérifié. Si vous rencontrez des problèmes pour vous connecter, veuillez nous envoyer un email à <b>info@orozinosupport.com</b>.</span>,
    verify_email_error: "Le jeton de vérification est invalide"
}