export const es = {

    // ClaimWinningsAllowed.js
    claim_winnings_allowed_message: "Estamos revisando tus ganancias y nos pondremos en contacto contigo lo antes posible.",

    // WithdrawAllowed.js
    withdrawal_allowed_message: ({ sign, activeWithdrawal }) => <span>Actualmente tienes una retirada activa de {sign}{activeWithdrawal?.amount}. Por favor, espera mientras procesamos tu retiro antes de solicitar uno nuevo.</span>,

    // BonusRowCategory.js
    bonus_row_title: "Elige tu primer bono",
    bonus_row_title_2: "Elige tu próximo bono",

    // BonusTile.js
    bonus_tile_cta: "¡Reclama ahora!",

    // BonusTimer.js
    bonus_timer_expired: "¡Expira hoy!",

    // Deposit.js
    deposit_btn_back_to_amount: "Volver al monto del depósito",
    deposit_btn_back_to_payment_options: "Volver a opciones de pago",

    // DepositAmountSection.js
    deposit_amount_title: "Monto",
    deposit_amount_cta: "Depósito",
    deposit_amount_field: "Monto del depósito",
    deposit_amount_field_label: (sign) => {
        return `Monto del depósito (${sign})`
    },
    deposit_amount_limit: ({ sign, depositMinAmount, depositMaxAmount }) => {
        return `El monto del depósito debe estar entre ${sign}${depositMinAmount} y ${sign}${depositMaxAmount}.`

    },
    deposit_amount_bonus_limit: ({ sign, depositMinAmount, depositMaxAmount }) => {
        return `El monto del depósito para el bono seleccionado debe estar entre ${sign}${depositMinAmount}y ${sign}${depositMaxAmount}.`
    },

    // DepositBonusCard.js
    deposit_bonus_card_min_deposit: "Depósito mínimo:",

    // DepositBonusSection.js
    deposit_bonus_section_title: "Elige tu bono",
    deposit_bonus_section_title_selected: "Bono seleccionado:",

    // DepositPaymentMethod.js
    deposit_payment_method_title: "Elige tu método de pago",
    deposit_payment_method_bonus: "Bono extra del 15%",

    // DepositRedirect.js
    deposit_redirect_title: "Completa tu pago",
    deposit_redirect_cta: "Depositar",
    deposit_redirect_cta_processing: "Procesando",
    deposit_redirect_success_title: "¡Casi allí!",
    deposit_redirect_success_text: "Haz clic abajo para recargar y empezar a jugar.",
    deposit_redirect_success_cta: "Completa tu pago",
    deposit_redirect_error: (error) => {
        return `${error ? error : 'Ocurrió un error.'} Por favor, inténtalo de nuevo o elige otro método de pago.`;
    },
    deposit_redirect_field_first_name: "Nombre",
    deposit_redirect_field_last_name: "Apellido",
    deposit_redirect_field_email: "Correo electrónico",
    deposit_redirect_field_phone: "Teléfono",
    deposit_redirect_field_address: "Dirección",
    deposit_redirect_field_city: "Ciudad",
    deposit_redirect_field_state: "Provincia/Estado",
    deposit_redirect_field_zip: "Código Postal",

    // DepositSummary.js
    deposit_summary_amount: "Monto:",
    deposit_summary_bonus: "Bono:",
    deposit_summary_no_bonus: "Ningún bono seleccionado.",

    // DepositToggle.js
    deposit_toggle_btn_deposit: "Depositar",
    deposit_toggle_btn_withdraw: "Retirar",

    // FreespinsRow.js
    freespin_row_title: ({ number, game }) => <>Tienes<span className="">{number} giros gratis</span> en<span className="">{game}</span>!</>,
    freespin_row_cta: "Jugar ahora",

    // GameRowJackpotTypes.js
    jackpot_types_daily: "Jackpot diario",
    jackpot_types_hourly: "Jackpot por hora",
    jackpot_types_drops_in: "Caídas dentro de",

    // GameSearchField.js
    game_search_input_placeholder: "Nombre del juego o proveedor",
    game_search_input_hint: "Buscar",

    // IdleTimer.js
    idle_timer_title: "Sesión caducada",
    idle_timer_text: "Has estado inactivo. Por favor, inicia sesión nuevamente.",
    idle_timer_cta: "Iniciar sesión",

    // ModalClaimBonusWinnings.js
    claim_bonus_winnings_title: "Reclama tus ganancias",
    claim_bonus_winnings_processing: "Procesando...",
    claim_bonus_winnings_exceeded: "Tu solicitud fue enviada para su aprobación. Nos pondremos en contacto contigo lo antes posible.",
    claim_bonus_winnings_error: "Ocurrió un error. Por favor, intenta nuevamente o contacta con soporte.",

    // ModalCloseAccount.js
    close_account_title: "Cerrar cuenta",
    close_account_text: <span>¿Estás seguro de que deseas cerrar tu cuenta?<br /><br /><i>Para terminar tu autoexclusión, inicia sesión con tu correo electrónico y contraseña y sigue las instrucciones.</i></span>,
    close_account_cta: "Sí, cierra mi cuenta",

    // ModalOpenAccount.js
    open_account_title: "Cuenta cerrada",
    open_account_text: "Tu cuenta está cerrada en este momento. ¿Deseas abrir tu cuenta?",
    open_account_cta: "Sí, por favor abrir mi cuenta",

    // Navigation.js
    navigation_btn_home: "Inicio",
    navigation_btn_games: "Juegos",
    navigation_btn_log_in: "Iniciar sesión",
    navigation_btn_register: "Registrarse",
    navigation_balance: "Saldo",

    // PhoneVerification.js
    phone_verification_log_out_title: (number) => <>Obtén<span className="verify-phone-title-spins">{number}</span> sin deposito!&nbsp;</>,
    phone_verification_log_in_title: (number) => <>Verifica tu teléfono y obtén <span className="verify-phone-title-spins">{number}</span>!</>,
    phone_verification_field_code_title: "Ingresa tu código de confirmación",
    phone_verification_field_phone_label: "Ingresa tu número de celular",
    phone_verification_field_code_label: "Código",
    phone_verification_btn_claim: "Reclamar ahora",
    phone_verification_btn_verify_claim: "Verificar y reclamar",
    phone_verification_btn_sms: "SMS",
    phone_verification_btn_phone_call: "Llamada telefónica",
    phone_verification_btn_confirm: "Confirmar",
    phone_verification_btn_cancel: "Cancelar",
    phone_verification_btn_try_again: "Intentar de nuevo",
    phone_verification_confirmation_title: "¿Es este número correcto?",
    phone_verification_error_phone_already_verified: "Este teléfono ya ha sido verificado.",
    phone_verification_error_wrong_phone: "Este celular no coincide con el que está en tu perfil.",
    phone_verification_error_active_bonus: "Ya tienes un bono. Por favor, actualiza la página",
    phone_verification_error_general: "Ocurrió un error. Por favor, revisa tu teléfono e intenta nuevamente",
    phone_verification_example: "Por ejemplo:",

    // ProfilePlayerInformation.js
    profile_information_title: "Información de facturación",
    profile_information_btn_save: "Guardar",
    profile_information_field_first_name: "Nombre",
    profile_information_field_last_name: "Apellido",
    profile_information_field_email: "Correo electrónico",
    profile_information_field_phone: "Celular",
    profile_information_field_address: "Dirección",
    profile_information_field_city: "Ciudad",
    profile_information_field_state: "Provincia/Región",
    profile_information_field_zip: "Código Postal",
    profile_information_field_dob: "Fecha de nacimiento",
    profile_information_notifications_title: 'Preferencias de notificaciones',
    profile_information_notifications_updates: 'Reciba notificaciones sobre actualizaciones de cuenta, depósitos, retiros, tiempo de inactividad y mantenimiento programado.',
    profile_information_notifications_weekly: 'Recibe notificaciones sobre ofertas semanales gratuitas, actualizaciones de bonificaciones, nuevas promociones y lanzamientos de juegos.',

    // RegistrationForm.js
    registration_form_email_label: "Dirección de correo electrónico",
    registration_form_or: "O",
    registration_form_email_placeholder: "nombre@ejemplo.com",
    registration_form_password_label: "Contraseña",
    registration_form_key_hint: "Registrarse",
    registration_form_terms: <span>Tengo 18 años o más y acepto <a href="https://cepgucehw.gleap.help/en/articles/4-privacy-policy" target="_blank">Privacidad&nbsp;Política</a> y <a href="https://cepgucehw.gleap.help/en/articles/1-terms-and-conditions" target="_blank">Términos&nbsp;y&nbsp;Condiciones</a>.</span>,
    registration_form_btn_cta: "Crear cuenta gratuita",
    registration_form_verify_email_title: "Por favor, verifica tu correo electrónico",
    registration_form_verify_email_subtitle: "Hemos enviado un correo electrónico a la dirección que proporcionaste. Por favor, revisa tu bandeja de entrada y sigue las instrucciones para activar tu cuenta.",
    registration_form_error_email: "Por favor, proporciona una dirección de correo electrónico válida.",
    registration_form_error_email_exists: <span>Ese correo ya está registrado.<br />Intenta <a href="?login">iniciar sesión</a>.</span>,
    registration_form_error_password: "La contraseña debe tener al menos 7 caracteres.",
    registration_form_error_terms: "Por favor, acepta nuestros Términos y Condiciones para completar el registro.",
    registration_form_error_general: "Ocurrió un error. Por favor, inténtalo de nuevo.",
    registration_form_error_captcha: "El desafío CAPTCHA falló. Actualice la página y vuelva a intentarlo",
    
    // WeeklyBonus.js
    weekly_bonus_cta: "Reclama ahora",

    // Withdraw.js
    withdraw_balance: "Saldo disponible para retirar",
    withdraw_bonus_balance: "Saldo de bonificación",
    withdraw_wager_progress: "Progreso de apuesta",
    withdraw_choose_amount: "Ingresa el monto a retirar",
    withdraw_choose_amount_label: (sign) => `Monto (${sign})`,
    withdraw_error_insufficient_funds: "No tienes fondos disponibles para retirar",
    withdraw_error_wrong_amount_limit_min: ({ sign, minAmount }) => `Fondos insuficientes. El monto mínimo de retiro es ${sign}${minAmount}`,
    withdraw_error_wrong_amount_limit_min_max: ({ sign, minAmount, maxAmount }) => `El monto de retiro debe ser entre ${sign}${minAmount} y ${sign}${maxAmount}`,
    withdraw_btn_back_to_payment_options: "Volver a las opciones de retiro.",

    // WithdrawMethod.js
    withdraw_method_interac_legal: "®Marca registrada de Interac Corp. Usada bajo licencia.",
    withdraw_method_choose_amount: "Por favor, ingresa el monto para continuar.",

    // WithdrawRedirect.js
    withdraw_redirect_title: "Completa tu retiro.",
    withdraw_redirect_cta: "Retirar",
    withdraw_redirect_cta_processing: "Procesando...",
    withdraw_redirect_success_title: "¡Éxito!",
    withdraw_redirect_success_text_1: "Tu solicitud de retiro está siendo procesada y debería completarse dentro de 1 a 5 días hábiles, según el método de retiro.",
    withdraw_redirect_success_text_2: "Tan pronto como se procese tu retiro, el monto elegido será descontado de tu saldo.",
    withdraw_redirect_error: (error) => {
        return `${error ? error : 'Ocurrió un error.'} Por favor, vuelve a intentarlo o elige un método de retiro diferente.`;
    },
    withdraw_redirect_field_first_name: "Nombre",
    withdraw_redirect_field_last_name: "Apellido",
    withdraw_redirect_field_email: "Correo electrónico",
    withdraw_redirect_field_phone: "Teléfono",
    withdraw_redirect_field_address: "Dirección",
    withdraw_redirect_field_city: "Ciudad",
    withdraw_redirect_field_state: "Provincia/Región",
    withdraw_redirect_field_zip: "Código Postal",
    withdraw_redirect_field_card_number: "Número de tarjeta",
    withdraw_redirect_field_exp_month: "Mes de vencimiento (MM)",
    withdraw_redirect_field_exp_year: "Año de vencimiento (YY)",
    withdraw_redirect_wallet_id: "ID de billetera",

    // WithdrawSummary.js
    withdrawal_summary_amount: "Monto",

    // ForgotPassword.js
    forgot_password_title: "Restablecer tu contraseña",
    forgot_password_field_email: "Ingresa tu correo electrónico de registro.",
    forgot_password_text_1: "Revisa tu bandeja de entrada y la carpeta de spam.",
    forgot_password_text_2: "Si no recibes un correo electrónico en 30 minutos, por favor intenta enviar este formulario nuevamente o contacta al soporte.",
    forgot_password_btn_close: "Cerrar",
    forgot_password_btn_submit: "Enviar",

    // Login.js
    login_forgot_password_cta: "¿Olvidaste tu contraseña?",
    login_create_account: "¿Aún no tienes cuenta?",
    login_create_account_cta: "Registrarse",
    login_btn_close: "Cerrar",
    login_cta: "Iniciar sesión",

    // Profile.js
    profile_title: "Perfil del usuario",
    profile_balance: "Saldo total:",
    profile_balance_withdrawable: "Saldo disponible para retirar:",
    profile_balance_bonus: "Saldo de bono:",
    profile_wager_progress: "Progreso de apuesta:",
    profile_bonus_promocode: "Código promocional:",
    profile_bonus_expires: "Vence el",
    profile_btn_claim_winnings: "Reclama tus ganancias",
    profile_btn_bonus_cancel: "Cancelar bono",
    profile_btn_support: "Contactar soporte",
    profile_close_account: "¡Atención! Para iniciar el cierre de tu cuenta, por favor presiona el botón de abajo.",
    profile_btn_close_account: "Cerrar cuenta",
    profile_btn_log_out: "Cerrar sesión",

    // RegionBlocked.js
    region_blocked_title: "Región bloqueada.",

    // ResetPassword.js
    reset_password_title: "Restablecer tu contraseña",
    reset_password_field_new_password: "Nueva contraseña",
    reset_password_field_new_password_error: "Por favor, ingresa tu contraseña",
    reset_password_field_confirm_password: "Confirma tu contraseña",
    reset_password_field_confirm_password_error: "Por favor, vuelve a ingresar tu contraseña",
    reset_password_error: "Ocurrió un error. Por favor, intenta seguir el enlace de restablecimiento nuevamente o contacta al soporte.",
    reset_password_error_match: "¡Las contraseñas no coinciden! Por favor, corrige y vuelve a intentarlo.",
    reset_password_cta: "Restablecer contraseña.",
    reset_password_redirect_text: "Redirigiendo a la página de inicio...",

    // VerifyEmail.js
    verify_email_success_title: "¡Éxito! Tu correo electrónico ha sido verificado.",
    verify_email_success_subtitle: "Ahora serás redirigido a la página de inicio de sesión...",
    verify_email_error_title: "Tu cuenta ya está verificada",
    verify_email_error_subtitle: <span>Tu cuenta ya está verificada. Si tienes problemas para iniciar sesión, por favor envíanos un correo a <b>info@orozinosupport.com</b>.</span>,
    verify_email_error: "El token de verificación no es válido"
}