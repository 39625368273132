export const en = {
    
    // ClaimWinningsAllowed.js
    claim_winnings_allowed_message: 'We are currently reviewing your winnings and will contact you asap.',

    // WithdrawAllowed.js
    withdrawal_allowed_message: ({ sign, activeWithdrawal }) => <span>You currently have an active withdrawal for {sign}{activeWithdrawal?.amount}. Please wait while we process your withdrawal before requesting a new one.</span>,

    // BonusRowCategory.js
    bonus_row_title: "Choose your first bonus",
    bonus_row_title_2: "Choose your next bonus",

    // BonusTile.js
    bonus_tile_cta: 'Claim now',

    // BonusTimer.js
    bonus_timer_expired: 'Expires today!',

    // Deposit.js
    deposit_btn_back_to_amount: "Back to deposit amount",
    deposit_btn_back_to_payment_options: "Back to payment options",

    // DepositAmountSection.js
    deposit_amount_title: "Amount",
    deposit_amount_cta: "Deposit",
    deposit_amount_field: "Deposit amount",
    deposit_amount_field_label: (sign) => {
        return `Deposit amount (${sign})`
    },
    deposit_amount_limit: ({ sign, depositMinAmount, depositMaxAmount }) => {
        return `Deposit amount should be between ${sign}${depositMinAmount} and ${sign}${depositMaxAmount}.`
    },
    deposit_amount_bonus_limit: ({ sign, depositMinAmount, depositMaxAmount }) => {
        return `Deposit amount for selected bonus should be between ${sign}${depositMinAmount} and ${sign}${depositMaxAmount}.`
    },

    // DepositBonusCard.js
    deposit_bonus_card_min_deposit: "Min. deposit:",

    // DepositBonusSection.js
    deposit_bonus_section_title: "Pick your bonus",
    deposit_bonus_section_title_selected: "Selected bonus:",

    // DepositPaymentMethod.js
    deposit_payment_method_title: "Choose your payment method",
    deposit_payment_method_bonus: "Extra 15% bonus",

    // DepositRedirect.js
    deposit_redirect_title: "Complete your payment",
    deposit_redirect_cta: "Deposit",
    deposit_redirect_cta_processing: "Processing",
    deposit_redirect_success_title: "Almost there!",
    deposit_redirect_success_text: "Click below to top-up and start playing.",
    deposit_redirect_success_cta: "Complete your payment",
    deposit_redirect_error: (error) => {
        return `${error ? error : 'An error occured.'} Please try again or choose a different payment method.`;
    },
    deposit_redirect_field_first_name: 'First Name',
    deposit_redirect_field_last_name: 'Last Name',
    deposit_redirect_field_email: 'Email',
    deposit_redirect_field_phone: 'Phone',
    deposit_redirect_field_address: 'Address',
    deposit_redirect_field_city: 'City',
    deposit_redirect_field_state: 'Province/State',
    deposit_redirect_field_zip: 'Zip Code',

    // DepositSummary.js
    deposit_summary_amount: "Amount:",
    deposit_summary_bonus: "Bonus:",
    deposit_summary_no_bonus: "No bonus selected.",

    // DepositToggle.js
    deposit_toggle_btn_deposit: "Deposit",
    deposit_toggle_btn_withdraw: "Withdraw",

    // FreespinsRow.js
    freespin_row_title: ({ number, game }) => <>You have <span className="">{number} free spins</span> in <span className="">{game}</span>!</>,
    freespin_row_cta: "Play now",

    // GameRowJackpotTypes.js
    jackpot_types_daily: 'Daily jackpot',
    jackpot_types_hourly: 'Hourly jackpot',
    jackpot_types_drops_in: 'Drops within',

    // GameSearchField.js
    game_search_input_placeholder: "Enter game or provider",
    game_search_input_hint: "Search",

    // IdleTimer.js
    idle_timer_title: "Session timed out",
    idle_timer_text: "You have been idle. Please, login again.",
    idle_timer_cta: 'Login',

    // ModalClaimBonusWinnings.js
    claim_bonus_winnings_title: 'Claim your winnings',
    claim_bonus_winnings_processing: 'Processing...',
    claim_bonus_winnings_exceeded: 'Your request was sent for approval. We will get back to you as soon as possible.',
    claim_bonus_winnings_error: 'An error occurred. Please try again or contact support.',

    // ModalCloseAccount.js
    close_account_title: 'Close Account',
    close_account_text: <span>Are you sure you want to close your account?<br /><br /><i>In order to terminate your self-exclusion, log in with your email and password and follow further instructions.</i></span>,
    close_account_cta: 'Yes, close my account',

    // ModalOpenAccount.js
    open_account_title: 'Account closed',
    open_account_text: 'Your account is currently closed. Do you wish to open your account?',
    open_account_cta: 'Yes, please open my account',

    // Navigation.js
    navigation_btn_home: "Home",
    navigation_btn_games: "Games",
    navigation_btn_log_in: "Log in",
    navigation_btn_register: "Register",
    navigation_balance: "Balance",

    // PhoneVerification.js
    phone_verification_log_out_title: (number) => <>Get <span className="verify-phone-title-spins">{number}</span> with no deposit&nbsp;required!</>,
    phone_verification_log_in_title: (number) => <>Verify your phone and get <span className="verify-phone-title-spins">{number}</span>!</>,
    phone_verification_field_code_title: 'Enter your confirmation code',
    phone_verification_field_phone_label: 'Enter your mobile phone',
    phone_verification_field_code_label: 'Code',
    phone_verification_btn_claim: 'Claim now',
    phone_verification_btn_verify_claim: 'Verify and claim',
    phone_verification_btn_sms: 'SMS',
    phone_verification_btn_phone_call: 'Phone call',
    phone_verification_btn_confirm: "Confirm",
    phone_verification_btn_cancel: 'Cancel',
    phone_verification_btn_try_again: 'Try again',
    phone_verification_confirmation_title: "Is this number correct?",
    phone_verification_error_phone_already_verified: 'This phone has already been verified.',
    phone_verification_error_wrong_phone: 'This phone does not match with the phone in your profile.',
    phone_verification_error_active_bonus: 'You already have a bonus. Please refresh the page.',
    phone_verification_error_general: 'An error occurred. Please try again or contact our support team.',
    phone_verification_example: 'For example:',

    // ProfilePlayerInformation.js
    profile_information_title: 'Billing information',
    profile_information_btn_save: 'Save',
    profile_information_field_first_name: 'First Name',
    profile_information_field_last_name: 'Last Name',
    profile_information_field_email: 'Email',
    profile_information_field_phone: 'Phone',
    profile_information_field_address: 'Address',
    profile_information_field_city: 'City',
    profile_information_field_state: 'Province/Region',
    profile_information_field_zip: 'Zip Code',
    profile_information_field_dob: 'Date of Birth',
    profile_information_notifications_title: 'Notification Preferences',
    profile_information_notifications_updates: 'Receive notifications about account updates, deposits, withdrawals, downtime, and scheduled maintenance.',
    profile_information_notifications_weekly: 'Receive notifications on free weekly offers, bonus updates, new promotions, and game releases.',

    // RegistrationForm.js
    registration_form_email_label: "Email address",
    registration_form_or: 'OR',
    registration_form_email_placeholder: "name@example.com",
    registration_form_password_label: "Password",
    registration_form_key_hint: "Register",
    registration_form_terms: <span>I am 18 years old and I accept <a href="https://cepgucehw.gleap.help/en/articles/4-privacy-policy" target="_blank">Privacy&nbsp;Policy</a> and <a href="https://cepgucehw.gleap.help/en/articles/1-terms-and-conditions" target="_blank">Terms&nbsp;and&nbsp;Conditions</a>.</span>,
    registration_form_btn_cta: "Create free account",
    registration_form_verify_email_title: "Please verify your email",
    registration_form_verify_email_subtitle: "We've sent an email to the address you provided. Please check your inbox and follow the instructions to activate your account.",
    registration_form_error_email: "Please provide a valid email address.",
    registration_form_error_email_exists: <span>Such email already exists.<br />Maybe try <a href="?login">logging in</a>?</span>,
    registration_form_error_password: "Password needs to be at least 7 characters long",
    registration_form_error_terms: "Please agree to our Terms and Conditions to complete registration",
    registration_form_error_general: "An error occured. Please try again.",
    registration_form_error_captcha: "CAPTCHA challenge failed. Please refresh the page and try again",

    // WeeklyBonus.js
    weekly_bonus_cta: 'Claim now',

    // Withdraw.js
    withdraw_balance: 'Withdrawable balance',
    withdraw_bonus_balance: 'Bonus balance',
    withdraw_wager_progress: 'Wager progress',
    withdraw_choose_amount: 'Enter amount to withdraw',
    withdraw_choose_amount_label: (sign) => `Amount (${sign})`,
    withdraw_error_insufficient_funds: "You don't have any funds to withdraw",
    withdraw_error_wrong_amount_limit_min: ({ sign, minAmount }) => `Insuffient funds. Minimum withdrawal amount is ${sign}${minAmount}`,
    withdraw_error_wrong_amount_limit_min_max: ({ sign, minAmount, maxAmount }) => `Withdrawal should be between ${sign}${minAmount} and ${sign}${maxAmount}`,
    withdraw_btn_back_to_payment_options: 'Back to withdrawal options',

    // WithdrawMethod.js
    withdraw_method_interac_legal: '®Trade-mark of Interac Corp. Used Under License.',
    withdraw_method_choose_amount: 'Please enter amount to proceed',

    // WithdrawRedirect.js
    withdraw_redirect_title: 'Complete your withdrawal',
    withdraw_redirect_cta: 'Withdraw',
    withdraw_redirect_cta_processing: 'Processing...',
    withdraw_redirect_success_title: 'Success!',
    withdraw_redirect_success_text_1: 'Your withdrawal request is being processed and should be completed within 1 to 5 business days, depending on the withdrawal method.',
    withdraw_redirect_success_text_2: 'As soon as your withdrawal is processed, chosen amount will be deducted from your account balance.',
    withdraw_redirect_error: (error) => {
        return `${error ? error : 'An error occurred.'} Please try again or choose a different withdrawal method.`;
    },
    withdraw_redirect_field_first_name: 'First Name',
    withdraw_redirect_field_last_name: 'Last Name',
    withdraw_redirect_field_email: 'Email',
    withdraw_redirect_field_phone: 'Phone',
    withdraw_redirect_field_address: 'Address',
    withdraw_redirect_field_city: 'City',
    withdraw_redirect_field_state: 'Province/Region',
    withdraw_redirect_field_zip: 'Zip Code',
    withdraw_redirect_field_card_number: 'Card number',
    withdraw_redirect_field_exp_month: 'Exp. month (MM)',
    withdraw_redirect_field_exp_year: 'Exp. year (YY)',
    withdraw_redirect_wallet_id: 'Wallet Id',

    // WithdrawSummary.js
    withdrawal_summary_amount: "Amount:",

    // ForgotPassword.js
    forgot_password_title: 'Restore your password',
    forgot_password_field_email: "Enter your registration email",
    forgot_password_text_1: "Check your inbox and spam folder.",
    forgot_password_text_2: "If you don't receive an email within 30 minutes, please, try to submit this form again or contact support.",
    forgot_password_btn_close: "Close",
    forgot_password_btn_submit: "Submit",

    // Login.js
    login_forgot_password_cta: "Forgot password?",
    login_create_account: "Don't have an account yet?",
    login_create_account_cta: "Register",
    login_btn_close: "Close",
    login_cta: "Sign in",

    // Profile.js
    profile_title: 'Customer profile',
    profile_balance: 'Total balance:',
    profile_balance_withdrawable: 'Withdrawable balance:',
    profile_balance_bonus: 'Bonus balance:',
    profile_wager_progress: 'Wager progress:',
    profile_bonus_promocode: 'Promocode:',
    profile_bonus_expires: 'Expires on',
    profile_btn_claim_winnings: 'Claim your winnings',
    profile_btn_bonus_cancel: 'Cancel bonus',
    profile_btn_support: 'Contact support',
    profile_close_account: 'Caution! To initiate the closure of your account, please press the button below.',
    profile_btn_close_account: 'Close account',
    profile_btn_log_out: 'Log out',

    // RegionBlocked.js
    region_blocked_title: 'Region blocked.',

    // ResetPassword.js
    reset_password_title: 'Reset your password',
    reset_password_field_new_password: 'New password',
    reset_password_field_new_password_error: 'Please enter your password',
    reset_password_field_confirm_password: 'Confirm password',
    reset_password_field_confirm_password_error: 'Please re-enter your password',
    reset_password_error: 'An error occurred. Please try to follow the reset link again or contact support.',
    reset_password_error_match: 'Passwords do not match! Please, adjust and try again.',
    reset_password_cta: 'Reset Password',
    reset_password_redirect_text: 'Redirecting to home page...',

    // VerifyEmail.js
    verify_email_success_title: "Success! Your email is verified",
    verify_email_success_subtitle: "You will now be redirected to the login page...",
    verify_email_error_title: "Your account is already verified",
    verify_email_error_subtitle: <span>Your account is already verified. If you are having troubles logging in, please send us an email at <b>info@orozinosupport.com</b>.</span>,
    verify_email_error: "Verification token is invalid"
}