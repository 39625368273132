import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, FloatingLabel, Form, Container } from 'react-bootstrap';
import { dbVerifyEmail } from "../utilities/api-functions";
import { Helmet } from "react-helmet-async";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faClose } from '@fortawesome/free-solid-svg-icons';

/** Components */
import ErrorHandler from "../components/ErrorHandler";
import Loading from "../components/Loading";

/** Utilities */
import useText from "../utilities/use-text";

export default function VerifyEmail() {
    const location = useLocation();
    const text = useText();

    // const [errors, setErrors] = useState([]);

    const [isValid, setIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let params = new Proxy(new URLSearchParams(location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        if (!params.token) {
            setIsLoading(false);
            return;
        }

        dbVerifyEmail(params.token)
            .then((res) => {
                if (res?.data?.success) {
                    setIsValid(true);
                }

                // setErrors([{ message: text('verify_email_error') }]);
                setIsLoading(false);
            })
            .catch(error => {
                // setErrors([{ message: text('verify_email_error') }]);
                setIsLoading(false);
            });
    }, []);

    return (
        <>
            <Helmet>
                <title>{text('page_verify_email')}</title>
            </Helmet>

            <Container>
                {/* <ErrorHandler {...{ errors, setErrors }} /> */}
                {
                    isLoading && (
                        <div className="verify-email-container">
                            <div className="verify-email-wrapper">
                                <Loading />
                            </div>
                        </div>
                    )
                }

                {
                    !isLoading && <EmailVerified {...{ isValid }} />
                }
            </Container>
        </>
    );
}

function EmailVerified({ isValid }) {

    const navigate = useNavigate();
    const text = useText();

    useEffect(() => {
        if (isValid) {
            setTimeout(() => {
                navigate("/?cashier&welcome_modal=true")
            }, 5000)
        }
    }, []);

    return (
        isValid ? (
            <div className="verify-email-container">
                <div className="verify-email-wrapper">
                    <div className="verify-email-icon-wrap-success">
                        <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
                            <g stroke="currentColor" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                                <path className="circle" d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z" />
                                <path className="tick" d="M6.5 13.5L10 17 l8.808621-8.308621" />
                            </g>
                        </svg>
                    </div>
                    <div className="animate__animated animate__fadeInUp">
                        <h1 className="title-sm text-center mt-2">{text('verify_email_success_title')}</h1>
                        <p className="text-center">{text('verify_email_success_subtitle')}</p>
                    </div>
                </div>
            </div>
        ) : (
            <div className="verify-email-container">
                <div className="verify-email-wrapper">
                    {/* <div className="verify-email-icon-wrap-fail animate__animated animate__shakeY">
                        <FontAwesomeIcon icon={faCircleExclamation} />
                    </div> */}
                    <div className="verify-email-icon-wrap-success">
                        <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
                            <g stroke="currentColor" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                                <path className="circle" d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z" />
                                <path className="tick" d="M6.5 13.5L10 17 l8.808621-8.308621" />
                            </g>
                        </svg>
                    </div>
                    <div className="">
                        <h1 className="title-sm text-center mt-2">{text('verify_email_error_title')}</h1>
                        <p className="text-center font-mid" style={{ maxWidth: "600px" }}>{text('verify_email_error_subtitle')}</p>
                    </div>
                </div>
            </div>
        )
    )
}